import React from "react";
import { Modal } from 'antd';
import { Container, Row, Col } from "reusecore/Layout";
import SectionTitle from "reusecore/SectionTitle";
import Button from "reusecore/Button";
import VintageBox from "reusecore/VintageBox";
import Particle from "reusecore/Particle";
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';

import { FaPlay, FaRegUserCircle } from "react-icons/fa";

import BannerSectionWrapper, {StyledVideoModal} from "./banner.style";
import './styles.less';

const BannerDefault = () => {

  const onPlayVideo = () => {
    Modal.info({
      title: 'Play Video',
      className:'videoModal',
      maskClosable: true,
      maskStyle: {zIndex: 999},
      content: <Player 
        className='video' 
        autoPlay 
        src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
        />,
      width: '70%',
    });
  }
  return (
    <BannerSectionWrapper id='home'>
      <Particle />
      <Container fullWidthSM>
        <Row>
          <Col sm={7} md={6} lg={7}>
            <SectionTitle
              className="section-title"
              leftAlign={true}
              UniWidth="100%"
            >
              <h4>Free access for a limited time!</h4>
              <h1>
                Unlock the hidden value in your organisation with <span>Sortd</span>
              </h1>
            </SectionTitle>
            <p>
              Change and Business Analysis tools to help drive value out of your organisation.
            </p>
            <VintageBox right={true} vintageOne={true}>
              <Button className="banner-btn one">
                <FaRegUserCircle className="icon-left" />
                Get Early Access
              </Button>
              <Button className="banner-btn two" onClick={onPlayVideo}>
                <FaPlay className="icon-left" /> Learn More
              </Button>
            </VintageBox>
          </Col>
        </Row>
      </Container>
    </BannerSectionWrapper>
  );
};

export default BannerDefault;
