import React, {useState} from "react";



import Banner from 'sortd_sections/landing/Banner'; 
import Features from 'sortd_sections/landing/Features';
import Products from 'sortd_sections/landing/Products';
import BannerSVG from 'sortd_sections/NewDesign/Banner/BannerSVGAnim';

import Contact from 'sortd_sections/shared/Contact';



const IndexPage = ({ location }) => {
  const bannerButtons = [
    {
      text: ('继续了解'),
      link: 'products',
      type: 'primary',
    },
  ];

  const [animationStates, setAnimationState] = useState({
    play: true,
  });
  const onPlayVideo = () => {
    setAnimationState({
      play: false,
    });
  };
  const onCloseVideo = () => {
    setAnimationState({
      play: true,
    });
  };

  const notifications = [
    {
      type: ('New Product'),
      title: ('Requirements Pro Released!'),
      date: '2020.10.30',
      link: '/products/requirements-pro',
    },
    {
      type: ('New Product'),
      title: ('Change Pro Released'),
      date: '2020.10.30',
      link: '/products/change-pro',
    },
  ];

  return (
    <>
      <Banner
        className="banner"
        coverImage={<BannerSVG play={animationStates.play} />}
        title={('Sortd')}
        description={'Unlock the hidden value in your organisation with Sortd. We provide change and Business Analysis tools to help drive value out of your organisation.'}
        buttons={bannerButtons}
        video="https://gw.alipayobjects.com/mdn/rms_f8c6a0/afts/file/A*PDpiR4U2oFEAAAAAAAAAAABkARQnAQ"
        onPlayVideo={onPlayVideo}
        onCloseVideo={onCloseVideo}
        notifications={notifications}
      />
      <Products />
      <Features />
      <Contact />
  </>
  )
}

export default IndexPage
