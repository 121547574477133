import React from "react";
import { Link, navigate } from "gatsby";

import { Container, Row, Col } from "reusecore/Layout";
import SectionTitle from "reusecore/SectionTitle";
import Button from "reusecore/Button";
import VintageBox from "reusecore/VintageBox";

import integrationImage1 from "assets/images/app/mini-logo.png";
import integrationImage3 from "assets/images/app/reqpro.png";
import Icon from "assets/images/app/integrations/arrow.png";

import IntegrationItemWrapper from "./integrationSection.style";
import { FaPlay, FaRegUserCircle } from "react-icons/fa";

const Integrations = () => {
  return (
    <IntegrationItemWrapper id="products">
      <Container>
        <Row Vcenter={true}>
          <Col xs={12} sm={6}>
            <div className="integration__block__wrap">
              <div className="integration__block">
              <Link to="/products/change-pro">
                <div className="integration__block__inner">
                  <img src={integrationImage1} alt=" integration" />
                  <h3>Change Pro</h3>
                  <p>Deliver Organisational Change</p>
                  <Link to="/products/change-pro">
                    <img src={Icon} alt=" app" />
                  </Link>
                </div>
              </Link>
              </div>
              <div className="integration__block right">
              <Link to="/products/requirements-pro">
                <div className="integration__block__inner">
                  <img src={integrationImage3} alt=" integration" />
                  <h3>Requirements Pro</h3>
                  <p>Track & Prioritise Requirements</p>
                  <Link to="/products/requirements-pro">
                    <img src={Icon} alt=" app" />
                  </Link>
                </div>
                </Link>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6} className="integration-content-wrap">
            <SectionTitle
              className="section-title"
              leftAlign={true}
              UniWidth="100%"
            >
              <h4>Products</h4>

              <h2>
                Get sorted with <span>Sortd</span>
              </h2>
            </SectionTitle>
            <p className="integration-text">
              Our tools help drive value out of your organisations projects. 
              Whether you're looking at a full scale transformation or simply increasing user engagement on a small initiative, 
              Sortd can help.
            </p>
          </Col>
        </Row>
      </Container>
    </IntegrationItemWrapper>
  );
};

export default Integrations;
